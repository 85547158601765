<template>
  <div class="page home-page bg-primary-900 min-h-screen w-full pb-20">
    <!-- home header -->
    <section class="hero hero-home bg-cover bg-center bg-no-repeat relative">
      <div class="bg-gradient-to-t from-primary-900 to-transparent relative z-10">
        <div class="w-page page-padding mx-auto">
          <home-title></home-title>

          <home-search-bar
              :defaultKeywords="defaultKeywords"
              :keywords="keywords"
              :isLoading="isLoading"
              @search-start="handleSearchStart"
              @click-on-keyword="handleClickOnKeyword"></home-search-bar>
        </div>
      </div>
    </section>
    <!-- end of home header -->

    <div class="page-container w-page mx-auto my-0 page-padding">
      <home-service-list class="my-20 service-list"></home-service-list>
    </div>
    <div class="metrics">
      <home-metrics></home-metrics>
    </div>
    <div class="page-container w-page mx-auto my-0 page-padding">
      <home-modules :isLoading="isLoading" :modules="modules" :freeModules="freeModules"></home-modules>
    </div>
    <home-popup></home-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import Swiper from '../../components/swiper/swiper.vue';
import MetaHelper from '../../_helpers/meta.helper';
import HomePopup from './home/home-popup.vue';

@Component({
  name: 'Home',
  metaInfo() {
    return MetaHelper.accueil();
  },
  components: {
    HomePopup,
    Swiper,
    HomeSearchBar: () => import(/* webpackPrefetch: true */ '@/views/pages/home/home-search-bar.vue'),
    SearchBar: () => import(/* webpackPrefetch: true */ '@/components/search-bar/search-bar.vue'),
    HomeServiceList: () => import(/* webpackPrefetch: true */ '@/views/pages/home/home-service-list.vue'),
    HomeMetrics: () => import(/* webpackPrefetch: true */ '@/views/pages/home/home-metrics.vue'),
    HomeTitle: () => import(/* webpackPrefetch: true */ '@/views/pages/home/home-title.vue'),
    HomeModules: () => import(/* webpackPrefetch: true */ '@/views/pages/home/home-modules.vue'),
  },
  data() {
    return {
      defaultKeywords: 8,
    };
  },
  computed: {
    ...mapState({
      freeModules: (s) => s.home.freeModules,
      modules: (s) => s.home.modules,
      isLoading: (s) => s.home.loading,
      keywords: (s) => s.home.keywords,
    }),
  },
  methods: {
    goto(route) {
      this.$router.push(route);
    },
    handleClickOnKeyword(keyword) {
      this.$store.commit('search/bar/ADD_KEYWORD', keyword);
      this.$router.push({ name: 'Search' });
    },
    handleSearchStart() {
      this.$router.push({ name: 'Search' });
    },
  },
  async mounted() {
    await this.$store.dispatch('home/fetch');
  },
})
export default class Home extends Vue {
}
</script>

<style scoped>

</style>
