<template>
  <global-modal-element :animated="animated" :open="open" :use-background="false" :push-classes="'relative px-1 md:px-0'" :allow-overflow="true">
    <div
        :style="`background-image: ${bgCss}; height: ${height}px`"
        ref="display"
        class="popup-content block w-full rounded-xl shadow-2xl"></div>
    <div class="popup-cta flex">
      <a v-if="homePopup && homePopup.button"
          class="-mt-6 cursor-pointer bg-primary-500 text-white px-8 py-2 rounded-full m-auto transition hover:bg-white hover:text-primary-900"
          :href="homePopup.button.link" :target="homePopup.button.target">
        {{ homePopup.button.text }}
      </a>
      <button class="popup-close bg-primary-500 hover:bg-white absolute text-white hover:text-primary-900 p-2" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.099" height="15.099" viewBox="0 0 15.099 15.099">
          <path id="Union_5" data-name="Union 5" d="M12.748,14.7,7.55,9.5l-5.2,5.2A1.378,1.378,0,0,1,.4,12.748l5.2-5.2L.4,2.353A1.378,1.378,0,0,1,2.353.4l5.2,5.2,5.2-5.2A1.378,1.378,0,0,1,14.7,2.354L9.5,7.55l5.2,5.2A1.378,1.378,0,1,1,12.748,14.7Z" transform="translate(0 0)" class="fill-current"/>
        </svg>
      </button>
    </div>
  </global-modal-element>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import GlobalModalElement from '../../../components/global-modals/global-modal-element.vue';

@Component({
  name: 'home-popup',
  components: { GlobalModalElement },
  data() {
    return {
      bgCss: '',
      height: 0,
      animated: false,
    };
  },
  watch: {
    homePopup() {
      this.updateSize();
    },
  },
  computed: {
    ...mapState({
      homePopup: (state) => state.homePopup.popup,
      open: (state) => state.homePopup.open,
    }),
  },
  methods: {
    close() {
      this.$store.commit('homePopup/SET_OPEN', false);
    },
    computeSize() {
      if (!this.$refs.display) return 0;
      // eslint-disable-next-line no-mixed-operators
      return (this.homePopup.picture.height / this.homePopup.picture.width) * this.$refs.display.clientWidth;
    },
    computeBg() {
      if (!this.homePopup) return '';
      return `url(${this.homePopup.picture.path})`;
    },
    updateSize() {
      this.$nextTick(() => {
        this.bgCss = this.computeBg();
        this.height = this.computeSize();
        this.animated = true;
      });
    },
  },
})
export default class HomePopup extends Vue {
}
</script>

<style scoped>
  .popup-content {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .popup-close {
    top: -.875rem;
    right: -.875rem;
  }
  @media (max-width: 768px) {
    .popup-close {
      right: calc(50% - .875rem);
    }
  }
</style>
